'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function make(option, title, $staropt$star, $staropt$star$1, $staropt$star$2, correctAnswer, nextQues) {
  var description = $staropt$star !== undefined ? $staropt$star : /* array */[];
  var imageUrl = $staropt$star$1 !== undefined ? Caml_option.valFromOption($staropt$star$1) : undefined;
  var youtubeUrl = $staropt$star$2 !== undefined ? Caml_option.valFromOption($staropt$star$2) : undefined;
  return {
          option: option,
          title: title,
          description: description,
          imageUrl: imageUrl,
          youtubeUrl: youtubeUrl,
          correctAnswer: correctAnswer,
          nextQues: nextQues
        };
}

function option(t) {
  return t.option;
}

function title(t) {
  return t.title;
}

function description(t) {
  return t.description;
}

function youtubeUrl(t) {
  return t.youtubeUrl;
}

function imageUrl(t) {
  return t.imageUrl;
}

function correctAnswer(t) {
  return t.correctAnswer;
}

function nextQues(t) {
  return t.nextQues;
}

function makeArray(answers) {
  return $$Array.map((function (a) {
                return make(a.option, a.title, $$Array.map((function (d) {
                                  return d;
                                }), a.description), Caml_option.some(Caml_option.nullable_to_opt(a.imageUrl)), Caml_option.some(Caml_option.nullable_to_opt(a.youtubeUrl)), a.correctAnswer, a.nextQues);
              }), answers);
}

exports.make = make;
exports.option = option;
exports.title = title;
exports.description = description;
exports.youtubeUrl = youtubeUrl;
exports.imageUrl = imageUrl;
exports.correctAnswer = correctAnswer;
exports.nextQues = nextQues;
exports.makeArray = makeArray;
/* No side effect */
