'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Question = require("./Question.bs.js");
var ArrayUtils = require("../utils/ArrayUtils.bs.js");

function make(title, path, questions, buttonText, readMore, successMessage, description, language, type_) {
  return {
          title: title,
          path: path,
          questions: questions,
          buttonText: buttonText,
          readMore: readMore,
          successMessage: successMessage,
          description: description,
          language: language,
          type_: type_
        };
}

function title(t) {
  return t.title;
}

function path(t) {
  return t.path;
}

function questions(t) {
  return t.questions;
}

function buttonText(t) {
  return t.buttonText;
}

function readMore(t) {
  return t.readMore;
}

function successMessage(t) {
  return t.successMessage;
}

function description(t) {
  return t.description;
}

function language(t) {
  return t.language;
}

function type_(t) {
  return t.type_;
}

function makeArray(json) {
  return $$Array.map((function (a) {
                return make(a.title, a.path, Question.makeArray(a.questions), a.buttonText, a.readMore, a.successMessage, a.description, a.language, a.type_);
              }), json);
}

function findOpt(path, quiz) {
  var filteredQuizArray = quiz.filter((function (q) {
          return q.path === path;
        }));
  var match = ArrayUtils.isEmpty(filteredQuizArray);
  if (match) {
    return ;
  } else {
    return ArrayUtils.getOpt(0, filteredQuizArray);
  }
}

function filterByLang(language, quiz) {
  return quiz.filter((function (q) {
                return q.language === language;
              }));
}

function $$default(quiz) {
  return filterByLang("malayalam", quiz);
}

exports.make = make;
exports.title = title;
exports.path = path;
exports.questions = questions;
exports.buttonText = buttonText;
exports.readMore = readMore;
exports.successMessage = successMessage;
exports.description = description;
exports.language = language;
exports.type_ = type_;
exports.makeArray = makeArray;
exports.findOpt = findOpt;
exports.filterByLang = filterByLang;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* No side effect */
