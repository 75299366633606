'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Answer = require("./Answer.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function make(title, description, $staropt$star, $staropt$star$1, hint, mode, userAnswer, nextQues) {
  var imageUrl = $staropt$star !== undefined ? Caml_option.valFromOption($staropt$star) : undefined;
  var answers = $staropt$star$1 !== undefined ? $staropt$star$1 : /* array */[];
  return {
          title: title,
          description: description,
          answers: answers,
          hint: hint,
          imageUrl: imageUrl,
          mode: mode,
          userAnswer: userAnswer,
          nextQues: nextQues
        };
}

function title(t) {
  return t.title;
}

function description(t) {
  return t.description;
}

function answers(t) {
  return t.answers;
}

function hint(t) {
  return t.hint;
}

function imageUrl(t) {
  return t.imageUrl;
}

function mode(t) {
  return t.mode;
}

function userAnswer(t) {
  return t.userAnswer;
}

function nextQues(t) {
  return t.nextQues;
}

function makeArray(questions) {
  return $$Array.map((function (a) {
                return make(a.title, a.description, Caml_option.some(Caml_option.nullable_to_opt(a.imageUrl)), Answer.makeArray(a.answers), a.hint, a.mode, a.userAnswer, a.nextQues);
              }), questions);
}

function updateAnswer(userAnswer, t) {
  return {
          title: t.title,
          description: t.description,
          answers: t.answers,
          hint: t.hint,
          imageUrl: t.imageUrl,
          mode: t.mode,
          userAnswer: userAnswer,
          nextQues: t.nextQues
        };
}

function replace(index, q, questions) {
  return $$Array.mapi((function (i, question) {
                var match = i === index;
                if (match) {
                  return q;
                } else {
                  return question;
                }
              }), questions);
}

exports.make = make;
exports.title = title;
exports.description = description;
exports.answers = answers;
exports.hint = hint;
exports.imageUrl = imageUrl;
exports.mode = mode;
exports.userAnswer = userAnswer;
exports.nextQues = nextQues;
exports.makeArray = makeArray;
exports.updateAnswer = updateAnswer;
exports.replace = replace;
/* No side effect */
