'use strict';

var Data = require("../types/Data.bs.js");
var Quiz = require("../types/Quiz.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var React = require("react");
var QuizComponent = require("./QuizComponent.bs.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function str(prim) {
  return prim;
}

((require("./Home.css")));

var logo = (require('../assets/coronaSafeLogo.svg'));

var github_logo = (require('../assets/github.svg'));

var json = (require("./data.json"));

var data = Data.makeData(json);

function showHome(quiz) {
  return React.createElement("div", {
              className: "flex flex-wrap md:-mx-3 pt-4"
            }, $$Array.map((function (q) {
                    return React.createElement("div", {
                                className: "w-full pl-3 pr-4 md:px-3 pb-6"
                              }, React.createElement("div", {
                                    className: "home__container flex flex-col justify-between h-auto md:h-full border-2 border-gray-800 rounded-lg bg-orange-100 px-4 py-6 md:px-6"
                                  }, React.createElement("div", undefined, React.createElement("h3", {
                                            className: "leading-tight"
                                          }, Quiz.title(q)), React.createElement("p", {
                                            className: "pt-1 leading-snug"
                                          }, Quiz.description(q))), React.createElement("div", undefined, React.createElement("button", {
                                            className: "btn w-auto border-2 border-gray-800 bg-white hover:bg-gray-900 hover:text-white focus:text-white focus:bg-gray-900 button-xl mt-4 md:mt-5",
                                            onClick: (function (param) {
                                                return ReasonReactRouter.push(Quiz.path(q));
                                              })
                                          }, Quiz.buttonText(q)))));
                  }), quiz));
}

function showQuiz(path, quiz) {
  var match = Quiz.findOpt(path, quiz);
  if (match !== undefined) {
    return React.createElement(QuizComponent.make, {
                quiz: match
              });
  } else {
    return showHome(Quiz.$$default(quiz));
  }
}

function Home(Props) {
  var url = ReasonReactRouter.useUrl(undefined, /* () */0);
  var quiz = Data.quiz(data);
  var match = url.path;
  var tmp;
  if (match) {
    var baseUrl = match[0];
    switch (baseUrl) {
      case "english" :
          tmp = match[1] ? showHome(Quiz.$$default(quiz)) : showHome(Quiz.filterByLang("english", quiz));
          break;
      case "french" :
          tmp = match[1] ? showHome(Quiz.$$default(quiz)) : showHome(Quiz.filterByLang("french", quiz));
          break;
      case "kannada" :
          tmp = match[1] ? showHome(Quiz.$$default(quiz)) : showHome(Quiz.filterByLang("kannada", quiz));
          break;
      case "malayalam" :
          tmp = match[1] ? showHome(Quiz.$$default(quiz)) : showHome(Quiz.filterByLang("malayalam", quiz));
          break;
      case "urdu" :
          tmp = match[1] ? showHome(Quiz.$$default(quiz)) : showHome(Quiz.filterByLang("urdu", quiz));
          break;
      default:
        tmp = match[1] ? showHome(Quiz.$$default(quiz)) : showQuiz(baseUrl, quiz);
    }
  } else {
    tmp = showHome(Quiz.$$default(quiz));
  }
  return React.createElement("div", {
              className: "main"
            }, React.createElement("div", {
                  className: "bg-white w-full border-b border-gray-200"
                }, React.createElement("div", {
                      className: "max-w-screen-sm mx-auto"
                    }, React.createElement("div", {
                          className: "flex justify-between items-end py-4 pl-3 pr-4 md:px-0"
                        }, React.createElement("a", {
                              className: "w-2/5 md:w-1/3",
                              href: "/"
                            }, React.createElement("img", {
                                  className: "object-contain",
                                  title: "CoronaSafe: COVID-19 Literacy Mission",
                                  alt: "CoronaSafe Logo: COVID-19 Literacy Mission",
                                  src: logo
                                })), React.createElement("a", {
                              className: "font-semibold text-xs md:text-base",
                              href: "https://www.coronasafe.in/"
                            }, "COVID-19 Literacy Mission")))), React.createElement("div", {
                  className: "w-full md:max-w-screen-sm mx-auto mt-4"
                }, React.createElement("div", {
                      className: "text-gray-700 text-sm select-none px-3 pb-2 md:p-0 overflow-x-auto md:overflow-hidden"
                    }, React.createElement("a", {
                          className: "home-langualge-filter__link hover:text-gray-900 hover:border-gray-900 ",
                          onClick: (function (param) {
                              return ReasonReactRouter.replace("/english");
                            })
                        }, "English"), React.createElement("a", {
                          className: "home-langualge-filter__link hover:text-gray-900 hover:border-gray-900 ",
                          onClick: (function (param) {
                              return ReasonReactRouter.replace("/malayalam");
                            })
                        }, "മലയാളം")), tmp), React.createElement("footer", {
                  className: "bg-white w-full border-t mt-auto"
                }, React.createElement("div", {
                      className: "max-w-screen-sm mx-auto"
                    }, React.createElement("div", {
                          className: "flex justify-center items-center py-4 pl-3 pr-4 md:px-0"
                        }, React.createElement("a", {
                              className: "font-semibold text-xs md:text-base",
                              href: "https://github.com/coronasafe/awareness"
                            }, "A quiz based app for staying safe in COVID 19 Outbreak"), React.createElement("a", {
                              className: "",
                              href: "https://github.com/coronasafe/awareness"
                            }, React.createElement("img", {
                                  className: "object-contain github_svg ml-3 w-5",
                                  title: "Coronasafe  Awareness (Github)",
                                  alt: "CoronaSafe Awareness (Github)",
                                  src: github_logo
                                }))))));
}

var make = Home;

exports.str = str;
exports.logo = logo;
exports.github_logo = github_logo;
exports.json = json;
exports.data = data;
exports.showHome = showHome;
exports.showQuiz = showQuiz;
exports.make = make;
/*  Not a pure module */
