'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function Link(Props) {
  var match = Props.href;
  var href = match !== undefined ? match : "#";
  var onClick = Props.onClick;
  var className = Props.className;
  var children = Props.children;
  return React.createElement("a", {
              className: className,
              href: href,
              onClick: (function (e) {
                  e.preventDefault();
                  ReasonReactRouter.push(href);
                  return Curry._1(onClick, e);
                })
            }, children);
}

var make = Link;

exports.make = make;
/* react Not a pure module */
